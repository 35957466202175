$(function () {
    let browserTimeZone = moment.tz.guess();
    // guess user timezone
    $('.tz').val(browserTimeZone);

    // get timezone if it is already given; otherwise, guess it
    let givenTimeZone = $("#dropdownTimeZone").data("giventimezone") != undefined
        ? $("#dropdownTimeZone").data("giventimezone") : browserTimeZone;
    
    if ($("#dropdownTimeZone").length) {
        // load Time Zone List
        let select = document.getElementById("dropdownTimeZone");
        select.innerHTML = "";
        let timeZones = moment.tz.names();
        timeZones.forEach((timeZone) => {
            option = document.createElement("option");
            var tzName = timeZone;
            var tzFormat = moment.tz(timeZone).format('Z');
            option.textContent = timeZone + " (UTC" + tzFormat + ")";
            option.value = timeZone;
            if (timeZone == givenTimeZone) {
                option.selected = true;
            }
            select.appendChild(option);
        });
    }
});

function getDate(timestamp) {
    // Multiply by 1000 because JS works in milliseconds instead of the UNIX seconds
    var date = new Date(timestamp * 1000);

    var year = date.getUTCFullYear();
    var month = date.getUTCMonth() + 1; // getMonth() is zero-indexed, so we'll increment to get the correct month number
    var day = date.getUTCDate();
    var hours = date.getUTCHours();
    var minutes = date.getUTCMinutes();
    var seconds = date.getUTCSeconds();

    month = (month < 10) ? '0' + month : month;
    day = (day < 10) ? '0' + day : day;
    hours = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;
    seconds = (seconds < 10) ? '0' + seconds: seconds;

    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
}

/**
 * Calculate the time left for specific question to become active
 */
function timeDifference(activeTime) {
    var currentTime = new Date();
    var difference = currentTime.getTime() - (activeTime * 1000);
    var hoursDiff = Math.floor(difference / (1000 * 60 * 60));
    difference -= hoursDiff * 1000 * 60 * 60;
    var minutesDiff = Math.ceil(difference / (10000 * 60));

    return hoursDiff + "h" + minutesDiff;
}
